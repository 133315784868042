import React from "react";

const AffiliateSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="currentColor">
      <path d="M23.7 23.7Q25 22.1 25.625 20.4Q26.25 18.7 26.25 16.45Q26.25 14.2 25.625 12.5Q25 10.8 23.7 9.2Q27.5 8.35 30.375 10.35Q33.25 12.35 33.25 16.45Q33.25 20.55 30.375 22.55Q27.5 24.55 23.7 23.7ZM34.5 40V35.3Q34.5 32.75 33.2 30.55Q31.9 28.35 28.7 26.85Q37.35 27.95 40.525 30.05Q43.7 32.15 43.7 35.3V40ZM40 25.55V20.55H35V17.55H40V12.55H43V17.55H48V20.55H43V25.55ZM15.75 23.95Q12.45 23.95 10.35 21.85Q8.25 19.75 8.25 16.45Q8.25 13.15 10.35 11.05Q12.45 8.95 15.75 8.95Q19.05 8.95 21.15 11.05Q23.25 13.15 23.25 16.45Q23.25 19.75 21.15 21.85Q19.05 23.95 15.75 23.95ZM0 40V35.3Q0 33.55 0.925 32.125Q1.85 30.7 3.4 30Q7 28.4 9.825 27.7Q12.65 27 15.75 27Q18.85 27 21.65 27.7Q24.45 28.4 28.05 30Q29.6 30.7 30.55 32.125Q31.5 33.55 31.5 35.3V40ZM15.75 20.95Q17.7 20.95 18.975 19.675Q20.25 18.4 20.25 16.45Q20.25 14.5 18.975 13.225Q17.7 11.95 15.75 11.95Q13.8 11.95 12.525 13.225Q11.25 14.5 11.25 16.45Q11.25 18.4 12.525 19.675Q13.8 20.95 15.75 20.95ZM3 37H28.5V35.3Q28.5 34.5 28.1 33.8Q27.7 33.1 26.85 32.7Q23.4 31.1 21 30.55Q18.6 30 15.75 30Q12.9 30 10.525 30.55Q8.15 31.1 4.6 32.7Q3.85 33.05 3.425 33.775Q3 34.5 3 35.3ZM15.75 16.45Q15.75 16.45 15.75 16.45Q15.75 16.45 15.75 16.45Q15.75 16.45 15.75 16.45Q15.75 16.45 15.75 16.45Q15.75 16.45 15.75 16.45Q15.75 16.45 15.75 16.45Q15.75 16.45 15.75 16.45Q15.75 16.45 15.75 16.45ZM15.75 37Q15.75 37 15.75 37Q15.75 37 15.75 37Q15.75 37 15.75 37Q15.75 37 15.75 37Q15.75 37 15.75 37Q15.75 37 15.75 37Q15.75 37 15.75 37Q15.75 37 15.75 37Z" />
    </svg>
  );
};

export default AffiliateSVG;
