import { Link } from "gatsby";
import React, { useState } from "react";
import MobileHeader from "../header/mobile-header";
import { navLinks } from "../../data/links";
import { Avatar } from "evergreen-ui";
import { getAvatarString } from "../../utils/tools";
import { useReadLocalStorage } from "usehooks-ts";
import { AppUser } from "../../model/types";

const DashboardHeader = () => {
  const user = useReadLocalStorage<AppUser>("user");

  const [collapsed, setCollapsed] = useState<boolean>(true);

  function onMenuClick() {
    setCollapsed(!collapsed);
  }
  return (
    <>
      <header className="hidden lg:flex w-full h-20 border-b items-center">
        <div className="w-0 h-full lg:min-w-xxs xl:min-w-xs 2xl:min-w-sm"></div>
        <div className="container w-full flex justify-between">
          <ul className="flex items-center text-xs">
            {navLinks.map((link) => (
              <li key={link.href} className="mr-4">
                <Link to={link.href}>{link.title}</Link>
              </li>
            ))}
          </ul>
          <ul className="flex items-center text-xs">
            <li>
              <Link to="/dashboard/wallets/deposit" className="mr-4">
                Deposit
              </Link>
            </li>
            <li>
              <Link to="/dashboard/wallets/withdraw" className="mr-4">
                Withdraw
              </Link>
            </li>
            <li>
              <Link to="/dashboard/orders" className="mr-4">
                Transactions
              </Link>
            </li>
            <li>
              <Link to="/dashboard" className="flex ml-8">
                <Avatar name={getAvatarString(user)} size={40} />
              </Link>
            </li>
          </ul>
        </div>
      </header>
      <div className="container">
        <MobileHeader />
      </div>
    </>
  );
};

export default DashboardHeader;
