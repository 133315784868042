import React from "react";

const LogoutSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="currentColor">
      <path d="M18.75 22.5H36.15L31.05 17.4L33.2 15.25L42 24.05L33.3 32.75L31.15 30.6L36.25 25.5H18.75ZM23.55 6V9H9Q9 9 9 9Q9 9 9 9V39Q9 39 9 39Q9 39 9 39H23.55V42H9Q7.8 42 6.9 41.1Q6 40.2 6 39V9Q6 7.8 6.9 6.9Q7.8 6 9 6Z" />
    </svg>
  );
};

export default LogoutSVG;
